body {
  background-color: black;
  color: white;
}

/* Container styling */
.chat-container {
  width: 100%;
  overflow: hidden;
  background-color: #212121;
  /* Dark background for the container */
}

/* Chat item styling */
.chat-item {
  display: flex;
  align-items: center;
  background-color: #212121;
  /* Darker background for the chat item */
  color: white;
  position: relative;
  transition: transform 0.3s ease;
}

/* Chat content styling */
.chat-content {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  overflow: hidden;
}

.username {
  font-size: 16px;
  margin-right: auto;
}

.status {
  font-size: 12px;
  color: #888;
  margin-right: 10px;
}

.mute-icon {
  font-size: 14px;
}
.canvas-wrapper canvas {
  width: 100% !important;
  border-radius: 14px;
}
/* Slide actions */
.slide-actions {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #47e8a3;
  /* Light green background for the "More" button */
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.more-btn {
  color: white;
  font-size: 14px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}

.rotate-animation {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Toggle Switch Starts Here */
input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label.toggle {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px !important;
  height: 20px;
  background: rgb(255, 255, 255);
  padding: 0;
  display: block;
  border-radius: 100px;
  position: relative;
}

label.toggle:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 3px;
  width: 15px;
  height: 15px;
  background: #64EAAA;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked+label.toggle {
  background: #346f53;
}

input:checked+label.toggle:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

label.toggle:active:after {
  width: 15px;
}

/* Toggle Switch Ends Here */
.conversation {
  height: calc(100% - 12px);
  position: relative;
  /* background: #efe7dd url(https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg) repeat; */
  z-index: 0;
}

.conversation .conversation-container {
  height: calc(100vh - 160px);
  /* box-shadow: inset 0 10px 10px -10px #000000; */
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}
.chat-bg {
  box-shadow: inset 0 10px 10px -10px #000000;
}
.media-item {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #7676804f;
  padding: 6px 11px;
  border-radius: 6px;
}

.message.sent {
  background: #044641;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.received {
  background: #008177;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message:first-child {
  margin: 4px 0 0px;
}

.message {
  color: #ffffff;
  clear: both;
  line-height: 18px;
  font-size: 14px;
  padding: 8px;
  position: relative;
  margin: 4px 0;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata.size {
  float: left;
}

.metadata.size .time {
  color: #767a81;
  display: flex;
}

.metadata .time {
  color: #C7C7C7;
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #044641;
  top: 0;
  right: -10px;
}

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #008177 transparent transparent;
  top: 0;
  left: -10px;
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.main-wrapper {
  width: 450px;
  margin: 0 auto;
}

.h-scale {
  height: calc(100vh - 157px);
}

@media (max-width: 768px) {
  .main-wrapper {
    width: 92%;
  }
}